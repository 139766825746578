<template>
  <GenericsTopMenu @construct="displaydrawer" :reload="reload"
    @reloaded="reload = false" @notification="updateNotification"/>
  <v-row class="h-100 w-100" style="margin: 0px !important;">
    <GenericsCardMenu @newVersion="reload = true"/>
    <v-col class="h-100 w-100 pr-0 ms-10">
      <slot />
    </v-col>
    <GenericsRightSlider :rdrawer="rdrawer" :type="type" @close="rdrawer = false" :notifications="notif"/>
  </v-row>
</template>

<script>
export default {
data() {
    return {
      rdrawer: false,
      type: "",
      reload: false,
      notif: []
    };
  },
  methods: {
    displaydrawer(type) {
      this.rdrawer = !this.rdrawer
      this.type = type
    },
    updateNotification(notification) {
      this.notif = notification
    }
  }
}
</script>