<template>
    <v-col class="confiance-light-background pl-0 pr-0" cols="1" style="display: grid; position: fixed; height: 95%; max-width: fit-content">
      <v-list v-model:opened="open" class="confiance-light-background text-white text-style-classic text-center pl-0 pr-0 ml-0">
          <v-list-item class="pl-0 pr-0">
            <v-btn :class="isMenuItemActive('businesscase')" class="confiance-light-background text-white" value="Businesscase"
              flat icon="mdi-folder-multiple" @click="navigateTo('/businesscase')" size="small"/>
              <v-tooltip activator="parent" location="bottom">Businness case</v-tooltip>
          </v-list-item>
          <v-list-item>
            <v-btn :class="isMenuItemActive('workflow')" class="confiance-light-background text-white" value="Workflow"
              flat icon="mdi-graph" @click="navigateTo('/workflow')" size="small"/>
            <v-tooltip activator="parent" location="bottom">Workflow</v-tooltip>
          </v-list-item>
          <v-list-item>
            <v-btn :class="isMenuItemActive('trustworthy')" class="confiance-light-background text-white" value="Trustworthy"
              flat icon="mdi-clipboard-check" @click="navigateTo('/trustworthy')" size="small"/>
            <v-tooltip activator="parent" location="bottom">Trustworthy</v-tooltip>
          </v-list-item>
          <v-list-item>
            <v-btn class="confiance-light-background text-white" flat icon="mdi-eye" @click="loadMcda" size="small"/>
            <v-tooltip activator="parent" location="bottom">Overview / MCDA</v-tooltip>
          </v-list-item>
          <v-divider class="w-100 border-opacity-50 mt-2 confiance-dark-color" :thickness="2"/>
          <v-list-item>
            <v-btn class="confiance-light-background text-white" 
              size="small" flat @click="prepareProject" icon="mdi-content-save"/>
              <v-tooltip activator="parent" location="bottom">Save project</v-tooltip>
          </v-list-item>
          <v-list-item>
            <v-btn class="confiance-light-background text-white"
              size="small" flat icon="mdi-exit-to-app" @click="navigateTo('/project')"/>
            <v-tooltip activator="parent" location="bottom">Exit</v-tooltip>
          </v-list-item>
        </v-list>
    </v-col>
    <v-dialog v-model="displayMcda" width="100%" height="100%">
      <ModalMcda :spec="spec3" :system="dataconverted" :label="loadedProject.name" renderer="canvas"
        @selectionClickSignal="console.log('click')" @close="displayMcda = false"/>
      <!-- <Mcda style="height: 100%; width: 100%" :loadedProject="loadedProject.businesscase"/> -->
    </v-dialog>
    <v-dialog v-model="displayPush" width="100%" height="100%">
      <ModalSaveModal :project="projectToSave"
        @save="save" @exit="displayPush = false"/>
    </v-dialog>
</template>

<script>
import git from "~/services/git"
import project from "~/services/project"
import nuxtStorage from "nuxt-storage"
import { Project } from '~/entity/Project'
import spec from '~/specs/spec.vg.json';


export default {
  emits: ["newVersion"], 
  setup() {
    const router = useRouter()
    return {
      router: router
    }
  },
  data() {
      return {
          open: ["Business Case", "Trustworthy Attributes", "Requirements & Artifacts"],
          displayPush: false,
          displayMcda: false,
          projectToSave: {},
          loadedProject: null,
          spec3: JSON.parse(JSON.stringify(spec)),
          dataconverted: []
      }
  },
  methods: {
    async loadMcda(){
      this.loadedProject = new Project(await project.getProject(
        nuxtStorage.localStorage.getData("projectId")))
      this.dataconverted = []
      this.convertData()
      this.displayMcda = true
    },
    convertData() {
      this.dataconverted.push({
        id: parseInt('100' + this.loadedProject.businesscase.aiFunction.id),
        name: this.loadedProject.businesscase.aiFunction.name,
        description: this.loadedProject.businesscase.aiFunction.description,
        label: "function",
        elemTypeId: 0,
      })
      this.loadedProject.businesscase.aiFunction.twa.forEach((att) => {
          this.dataconverted.push({
            id: parseInt('4000' + att.id),
            parent: parseInt('100' + this.loadedProject.businesscase.aiFunction.id),
            name: att.name,
            description: att.desc,
            label: "attribute",
            elemTypeId: 2,
          })
          if (att.property && att.property.requirement) {
            att.property.requirement.forEach((req) => {
              this.dataconverted.push({
                id: parseInt('6000' + req.id),
                parent: parseInt('4000' + att.id),
                name: req.name,
                description: req.desc,
                label: "requirement",
                elemTypeId: 3,
              })
              req.artifact.forEach((art) => {
                this.dataconverted.push({
                  id: parseInt('8000' + art.artifact.id),
                  parent: parseInt('6000' + req.id),
                  name: art.artifact.name,
                  description: art.artifact.desc,
                  label: "artifact",
                  elemTypeId: 4,
                })
              });
            });
          }
      })
      this.loadedProject.businesscase.aiFunction.aiComponent.forEach((component) => {
        this.dataconverted.push({
          id: parseInt('2000' + component.id),
          parent: parseInt('100' + this.loadedProject.businesscase.aiFunction.id),
          name: component.name,
          description: component.desc,
          label: "component",
          elemTypeId: 1,
        })
        component.twa.forEach((att) => {
          this.dataconverted.push({
            id: parseInt('4000' + att.id),
            parent: parseInt('2000' + component.id),
            name: att.name,
            description: att.desc,
            label: "attribute",
            elemTypeId: 2,
          })
          if (att.property && att.property.requirement) {
            att.property.requirement.forEach((req) => {
              this.dataconverted.push({
                id: parseInt('6000' + req.id),
                parent: parseInt('4000' + att.id),
                name: req.name,
                description: req.desc,
                label: "requirement",
                elemTypeId: 3,
              })
              req.artifact.forEach((art) => {
                this.dataconverted.push({
                  id: parseInt('8000' + art.artifact.id),
                  parent: parseInt('6000' + req.id),
                  name: art.artifact.name,
                  description: art.artifact.desc,
                  label: "artifact",
                  elemTypeId: 4,
                })
              });
            });
          }
        });
      });
    },
    async prepareProject() {
      this.displayPush = true
      this.projectToSave = new Project(await project.getProject(
        nuxtStorage.localStorage.getData("projectId")))
    },
    async save(event) {
      const exportedProject = await project.updateProject(new Project({
        id: event.project.id,
        name: event.project.name,
        version: event.project.version,
        description: event.project.description,
        owner: event.project.owner,
        users: event.project.users
      }))
      await git.exportProject(exportedProject.result, event.msg)
      this.$emit('newVersion')
      this.displayPush = false;
    },
    isMenuItemActive(route) {
      if(route === useRoute().name) { return 'confiance-dark-color-background' };
    }
  },
}
</script>
