<template>
 <v-card class="w-100 h-100 text-center overflow-y-auto">
    <v-text-field label="Search ..."
      v-model="searchData" type="text"
      @keyup.enter="searchInKnowledgeHub"
      id="search-input" class="ms-2 mr-2"/>
      <v-row class="w-100 flex-row-reverse">
        <div class="ml-5">
          <v-switch v-model="isEnglish" hide-details
              color="success" inset/>
        </div>
        <span class="mt-4">
          {{ !isEnglish ? "French" : "Anglais" }}
        </span>
    </v-row>
    <v-progress-circular style="margin-top: 10%;" v-if="showSpinner" color="blue-lighten-3" indeterminate :size="44"></v-progress-circular>
    <div v-if="showSpinner" class="spinner-label ">{{ isEnglish ? "Search in progress ..." : "Recherche en cours ..."}}</div>
    <v-col class="overflow-y-auto mt-5" style="max-height: 700px" v-else>
      <v-list-item v-for="document in result?.vectorDocumentList" :key="document.id" class="info-items" >
        <div class="name">{{ isEnglish ? document.embedding_metadata.name_en :  document.embedding_metadata.name_fr}}</div>
        <div class="definition">{{ isEnglish ? document.embedding_metadata.definition_en :  document.embedding_metadata.definition_fr}}</div>
        <div class='info-description'
        @click="showDescriptionModal=true;current_document=document"
        v-if="isEnglish ? 
        document.embedding_metadata.definition_en.length > 76 :  
        document.embedding_metadata.definition_fr.length > 76 ">{{ isEnglish ? "View Full Description":  "Voir toute la description"}}</div>
        <a v-if=  "document.embedding_metadata.source" :href="document.embedding_metadata.source" class='info-link' target="_blank">
          {{ isEnglish ? "View Document":  "Voir le document"}}
        </a>
      </v-list-item >
    </v-col>
    <v-alert v-if="result && result.error" :text="isEnglish ? 'Oups Something Went Wrong :(' : 'Oups il y\'a une erreur :('" icon="" type="error" closable style="z-index:10000!important;"></v-alert>
    <v-dialog v-model="showDescriptionModal" width="100%" height="100%">
      <v-card class="w-80 ma-10 overflow-hidden">
        <v-row class="w-100 justify-space-between">
          <h2 class="ma-10">{{ isEnglish ? current_document.embedding_metadata.name_en :  current_document.embedding_metadata.name_fr}}</h2>
          <v-icon class="ma-10 text-grey" icon="mdi-close-circle" @click="showDescriptionModal=false" />
        </v-row>
        <v-row class="text-center mb-10">
          <v-card-text class="ms-2 mt-2 text-style-classic">
            {{ isEnglish ? current_document.embedding_metadata.definition_en :  current_document.embedding_metadata.definition_fr}}
          </v-card-text>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>
 
<script>
import glossary from '~/services/glossary';

export default {
  data() {
     return {
       searchData : "",
       isEnglish : true,
       showDescriptionModal: false,
       current_document: null,
       showSpinner: false,
       result: null
    }
   },
   setup() {
        const env = useRuntimeConfig()
        const similaritySearchStore = useSimilaritySearch();
        return {store: similaritySearchStore, env: env}
    },
   methods: {
    async searchInKnowledgeHub() {
        this.showSpinner = true;
        const searchRequest = {
            query: this.searchData,
          }
        // Perform a search
        if (searchRequest.query ) {
          this.result = await glossary.getSimilaritySearch(searchRequest)
          this.showSpinner = false;
        }
      }
   }
 }
</script>
<style>
.info-items {
  margin-bottom:10px;
  border-top:1px solid #80808040;
  padding-top: 18px!important;
}
.name {
  font-weight: bold;
    font-size: 16.5px;
    text-align: left;
    text-transform: capitalize;
}
.full-description-name {
  font-weight: bold;
    font-size: 16.5px;
    margin-top: 7%;
    text-align: left;
    margin-left: 6%;
}
.definition {
    font-size: 13px;
    text-align: left;
    color:gray;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    
    overflow: hidden;
}
.full-description {
  font-size: 14px;
    font-weight: bold;
    color: rgba(4, 4, 4, 0.74);
    text-align: left;
    margin-top: 2%;
    margin-left: 6%;
}
#search-input {
  background-color:white!important;
}
.info-link {
   text-decoration: none;
    text-align: left;
    display: block;
    color: #00647d;
    font-size: 13px;
    font-weight: 600;
}
.info-description {
   text-decoration: none;
    text-align: right;
    display: block;
    color: #00647d;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
}
.description-modal {
  height: 100%;
    width: 100%;
    position: absolute;
    display: block;
    z-index: 1000!important;
    background-color: white;
    top: 118px;
}
.spinner-label {
  font-size: 14px;
    font-weight: bold;
    color: gray;
    text-align: center;
}
</style>
 