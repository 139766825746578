<template>
  <v-card class="w-80 ma-10 overflow-hidden">
    <v-row class="w-100 mt-2 justify-space-between">
        <v-col>
            <h2 class="ms-10 mylabel">Overview project {{ label }}</h2>
        </v-col>
        <v-col class="text-center">
          <v-responsive>
            <v-text-field v-model="inputData" append-inner-icon="mdi-magnify"
              label="Search" @keydown.enter.stop="updateData"/>
          </v-responsive>
        </v-col>
        <v-col cols="1" class="mt-3 text-center">
            <v-btn rounded color="#ABBAB2" class="text-style-classic text-button text-white"
                @click="closeModal" size="small">
                Close
            </v-btn>
        </v-col>
    </v-row>
    <v-sheet style="height: 90%;">
      <div ref="vegaContainer"
        style="position: relative; width: 100%; height: 100%; overflow: auto"/>
    </v-sheet>
  </v-card>
</template>

<script>
import * as vega from "vega";

// #emits are declared in spec.vega signal and added with addSignalEmitter
// const emits = defineEmits([
//   
// ]);

export default {
  emits: [
    "close",
    "selectionId",
    "selectionClickSignal",
    "mouseoverSignal",
    "offset",
    "nodesSize"
  ], 
  props: {
    spec: {
      type: Object,
      default: null,
    },
    system: {
      type: Object,
      default: null,
    },
    label: {
        type: String,
        default: ""
    },
    autosize: {
      type: Object,
      default: () => {
        return {
          type: "pad",
          contains: "padding",
          autosize: false,
        };
      },
    },
  },
  data() {
    return {
      renderer: "svg",
      vegaContainer: null,
      view: null,
      loadedData: null,
      width: null,
      height: null,
      padding: null,
      background: null,
      hover: "hover",
      inputData: "",
    };
  },
  mounted() {
    this.vegaContainer = this.$refs.vegaContainer
    this.createView();
  },
  onBeforeUnmount() {
    if (this.view && this.view.finalize) {
      this.view.finalize();
    }
  },
  watch: {
    system(val) {
        if (val != this.spec)
            this.createView();
    },
  },
  methods: {
    createView() {
      if (this.view && this.view.finalize) {
        this.view.finalize();
      }
      if (this.spec && this.vegaContainer) {
        const runtime = vega.parse(this.spec);
        this.view = new vega.View(runtime)
          .logLevel(vega.Warn)
          .renderer(this.renderer)
          .hover(this.hover);
        this.view.initialize(this.vegaContainer);
        const containerWidth = this.vegaContainer.clientWidth;
        const containerHeight = this.vegaContainer.clientHeight;
        this.view.width(containerWidth).height(containerHeight);
        if (this.system) {
          this.loadedData = vega.read(this.system, { type: "json", parse: "auto" });
          this.view.data("tree", this.loadedData);
        } else {
          console.error("No data provided");
        }
        this.addSignalEmitter(this.spec, this.view);
        this.view.runAsync();
      }
    },
    updateData() {
      if (
        typeof this.inputData === "string" &&
        this.inputData.trim() !== ""
      ) {
        this.loadedData = vega.read(this.system, {
          type: "json",
          parse: "auto",
        });
        const tree = this.loadedData;

        const lowerCaseInput = this.inputData.toLowerCase();
        const item = tree.find(
          (node) => node.name.toLowerCase() === lowerCaseInput
        );
        
        if (item) {

          this.view.signal("offset", {
            x: -item.x + this.vegaContainer.clientWidth / 2,
            y: -item.y - this.vegaContainer.clientHeight / 3,
            drag: false,
          });
          this.view.signal("selectionId", item.id);
          this.view.runAsync();
        }
      } else {
        this.resetData();
      }
    },
    resetData() {
      this.view.signal("offset", {
        x: 0,
        y: 0,
        drag: false,
      });
      this.view.runAsync();
    },
    addSignalEmitter(view) {
      if (view && this.spec && this.spec.signals.length > 0) {
        this.spec.signals.forEach((signal) => {
          this.view.addSignalListener(signal.name, (name, value) =>
            this.$emit(name, value)
          );
        });
      }
    },
    closeModal() {

      this.$emit('close')
    }
  },
};
</script>
