<template>
    <v-col cols="2" style="right:0; top:5%; z-index: 20; position: fixed;"
        class="h-100 pr-0" v-if="visible">
        <v-card width="100%" v-if="type != 'notification' && enableKnowledge">
            <v-row class="w-100 justify-space-between">
                <v-col class="ma-5">
                    <h2>Glossary</h2>
                </v-col>
                <v-col cols="1" class="ma-5">
                    <v-icon class="text-grey" icon="mdi-close-circle" @click="closeModal"/>
                </v-col>
            </v-row>
            <knowledgehub-ask/>
        </v-card>
        <v-card v-else class="h-100">
            <v-col class="h-100">
                <v-row class="w-100 justify-space-between">
                    <v-col class="ma-5">
                        <h2>Notifications</h2>
                    </v-col>
                    <v-col cols="1" class="mt-5">
                        <v-icon class="text-grey" icon="mdi-close-circle" @click="closeModal"/>
                    </v-col>
                </v-row>
                <v-row class="h-100">
                    <v-card-text class="text-style-classic text-body-2 ml-5" v-if="!notifExist">
                        No new notifications
                    </v-card-text>
                    <v-list style="height: 88%;" class="overflow-y-auto" v-else>
                      <v-list-item v-for="(notif, index) in notificationToDisplay" :key="index" class="info-items">
                        <h4 class="confiance-light-color">{{ notif.project }}</h4>
                        <h5 class="ml-4 confiance-light-color">[{{ notif.type }}]: {{ notif.name }}</h5>
                        <v-card-text class="text-style-classic text-body-2 ms-2 mt-n2">
                            {{ notif.description }},
                            the {{ new Date(notif.date).toLocaleDateString("en-US", options) }},
                            at {{ new Date(notif.date).toLocaleTimeString('en-US') }}
                        </v-card-text>
                    </v-list-item >
                    </v-list>
                </v-row>
            </v-col>
        </v-card>
    </v-col>
</template>

<script>
import nuxtStorage from "nuxt-storage"
import { Notification } from "~/entity/Notification";

export default {
    props: {
        rdrawer: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: ""
        },
        notifications: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            visible: false,
            notificationToDisplay: null,
            options: {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            },
            notifExist: false,
        };
    },
    setup() {
        const enablenowledge = useRuntimeConfig().public.enable_knowledge;
        return {
            enableKnowledge: enablenowledge
        };
    },
    watch: {
        async rdrawer(val) {
            this.visible = val;
            if (this.notifications.length == 0) {
                const storedNotif = await nuxtStorage.localStorage.getData("notification")
                this.notificationToDisplay = []
                if (storedNotif) {      
                  for (const notif of storedNotif) {
                    this.notificationToDisplay.push(new Notification(notif))
                  }
                }
            } else
                this.notificationToDisplay = this.notifications
            if (this.notificationToDisplay?.length > 0)
                this.notifExist = true
        },
    },
    methods: {
        closeModal() {
            this.$emit('close');
        }
    },
}
</script>
<style>
.doc-title {
    font-size: 18px!important;
    padding: 20px!important;
    font-weight: bold;
}
</style>