<template>
    <v-card class="w-80 ma-10 overflow-hidden">
        <h2 class="ms-10 mt-5 mylabel">Save Project</h2>
        <v-col class="w-100 h-80 ms-5">
            <v-card-text class="text-style-classic">
                You are about to save the following project : {{ project.name }}
            </v-card-text>
            <v-form ref="pushForm" v-model="validpush" lazy-validation style="width: 100%;">
                <v-text-field v-model="msg" label="Commit" class="mr-10" required :rules="msgRules"/>
                <v-text-field v-model="project.version" label="Project version" class="mr-10"/>
                <v-card-text class="text-style-classic text-center">
                    Are you sure ?
                </v-card-text>
                <v-row class="mt-3 text-center">
                    <v-col>
                        <v-btn color="#1F4259" class="ma-5 text-style-classic text-button"
                            rounded size="small" @click="closeAndSave" :disabled="!validpush" >
                            Save
                        </v-btn>
                        <v-btn color="#ABBAB2" class="ma-5 text-style-classic text-button text-white"
                            rounded size="small" @click="closeAndCancel">
                            Cancel
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-card>
</template>

<script>
export default {
    props: {
        project: {
            type: Object,
            default: {}
        },
    },
    data() {
        return {
            msg: null,
            msgRules: [
                v => !!v || 'message is required'
            ], 
            validpush: false,
        }
    },
    methods: {
        closeAndSave() {
            if (this.$refs.pushForm.validate()) {
                this.$emit('save', {msg: this.msg, project: this.project});
                this.$refs.pushForm.reset()
            }
        },
        closeAndCancel() {
            this.$emit('exit');
        }
    }
}
</script>